import React, { useRef } from 'react';
import useOnScreen from '../../Shared/Hooks/useOnScreen';
import { StyledProps } from '@glitz/react';
import { styled } from '../../Theme';

type PropType = StyledProps & {
  src: string;
  alt: string;
  imgFit?: boolean;
  onLoad?: () => void;
  usePlaceHolder?: boolean;
  title?: string;
  lazy?: boolean;
};

const Image = ({
  src,
  alt = '',
  onLoad,
  usePlaceHolder,
  title = '',
  lazy = true,
  compose,
}: PropType) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen({ ref });

  return onScreen || !lazy ? (
    <styled.Img
      css={compose()}
      src={src}
      alt={alt}
      title={title}
      aria-label={alt}
      onLoad={onLoad}
    />
  ) : usePlaceHolder ? (
    <div ref={ref} />
  ) : (
    <span ref={ref} />
  );
};

export default Image;
