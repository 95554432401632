import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ArrowDown({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      width="36"
      height="20"
      viewBox="0 0 36 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.612.729a1.273 1.273 0 0 0-1.798 0L18 16.574 2.155.729A1.273 1.273 0 0 0 .357 2.527L17.07 19.24c.248.248.558.372.899.372.31 0 .651-.124.9-.372L35.58 2.527A1.246 1.246 0 0 0 35.612.73z"
        fillRule="nonzero"
      />
    </styled.Svg>
  );
}

export default styled(ArrowDown);
