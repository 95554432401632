import React, { useState, useEffect, useRef } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import ICategoryPage from './Models/CategoryPageModel.interface';
import { InitCategoryItems, GetCategoryItems } from '../SearchPage/Search';
import ProductCard from '../ProductCard/ProductCard';
import CardGrid from '../Shared/Grid/CardGrid';
import { theme, styled } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media, pseudo } from '@glitz/core';
import ProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import IHeaderLink from '../Shared/Models/Headers/HeaderLink.interface';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import useMedia from '../Shared/Hooks/useMedia';
import ArrowDown from '../Shared/Icons/ArrowDown';
import KexLink from '../Shared/KexLink/KexLink';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import FilterContainerDesktop from '../FilterContainerDesktop/FilterContainerDesktop';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import SearchSorter from '../Search/Models/SearchSorter.interface';
import { useKexLoadingCircle } from '../Shared/Loading/KexLoadingCircle/KexLoadingCircle';
import ArrowDownThick from '../Shared/Icons/ArrowDownThick';
import useOutsideClick from '../Shared/Hooks/useOutsideClick';
import { mediaQuery } from '../Theme/mediaQueries';
import KexContentLinkModel from '../Search/Models/KexContentLinkModel.interface';
import LoadMoreContainer from '../Shared/LoadMoreContainer/LoadMoreContainer';
import FilterItem from '../Shared/FilterItem/FilterItem';
import { getUrlParameter } from '../Shared/Common/Helpers';

const cache = {
  products: {
    items: [] as any[],
    filters: [] as any[],
    sorters: [] as any[],
    availableItems: 12,
    nrOfItems: 0,
    visibleItems: [] as any[],
  },
  filters: [] as any,
  pathname: '',
};

function CategoryPage() {
  const {
    translations: {
      'common/filter': filterLabel,
      'common/sector': sectorLabel,
      'common/showMore': showMoreLabel,
      'categoryPage/sortOn': sortOnLabel,
      'categoryPage/noFilterResult': noFilterResultLabel,
    },
    languageRoute,
    commerceLinks,
    requestToken,
  } = useAppSettingsData();
  const {
    pageHeading,
    pageIntroText,
    pageId,
    channelId,
    breadcrumb,
    itemsPerLoad,
  } = useCurrentPage<ICategoryPage>();

  const [preFetchedData, setPreFetchedData] = useState<any>(cache.products);

  const dispatchLoading = useKexLoadingCircle();
  const { organizationId } = useUserStateData();

  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState<SearchSorter>();
  const [sorters, setSorters] = useState<SearchSorter[]>();

  const [sorterIsOpen, setSorterIsOpen] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [nrOfVisibleItems, setNrOfVisibleItems] = useState<number>(
    itemsPerLoad
  );

  const disableButton =
    preFetchedData &&
    cache.products?.visibleItems?.length >= cache.products.nrOfItems;

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const topOptionsRef = useRef<HTMLDivElement>(null);

  const filterRef = useRef<HTMLDivElement>(null);
  const sorterRef = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<HTMLDivElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filters, setFilters] = useState<KexContentLinkModel[]>([]);

  useEffect(() => {
    if (
      cache.pathname &&
      cache.pathname === window.location.pathname &&
      cache.products
    ) {
      setPreFetchedData((prev: any) => ({
        ...prev,
        products: cache.products,
      }));
      setFilters(cache.products.filters);
      setSorters(cache.products.sorters);
      setSelectedSorter(
        cache.products.sorters.find((x: SearchSorter) => x.selected)
      );
      let arr: string[] = [];
      if (
        cache.products.filters[0] &&
        !!cache.products.filters[0].links.length
      ) {
        const cachedSelectedFilters = cache.products.filters[0].links.filter(
          (x: any) => x.isSelected === true
        );
        cachedSelectedFilters.map((x: any) =>
          arr.push(`&${cache.products.filters[0].attributes.value}=${x.name}`)
        );
        cache.products.filters[0].links.find((x: any) => x.isSelected);
        setSelectedFilters(arr);
      }
      return;
    }
    dispatchLoading('add');
    InitCategoryItems(
      requestToken,
      organizationId,
      languageRoute,
      pageId,
      channelId
    ).then(data => {
      if (
        data &&
        data.productSearchResult &&
        data.productSearchResult.numberOfItems > 0
      ) {
        setPreFetchedData((prev: any) => ({
          ...prev,
          products: data.productSearchResult,
          filters: data.productFilters,
        }));
        cache.pathname = window.location.pathname;
        if (data.productSearchResult.sorters) {
          cache.products.sorters = data.productSearchResult.sorters;
        }
        cache.products.availableItems = data.productSearchResult.availableItems;
        let count = getUrlParameter('count');
        let sliced = data.productSearchResult.items.slice(
          0,
          count ? parseInt(count) : itemsPerLoad
        );
        if (data.productSearchResult.items) {
          cache.products.items = data.productSearchResult.items;
        }
        cache.products.filters = data.productFilters;
        cache.products.nrOfItems = data.productSearchResult.numberOfItems;
        cache.products.visibleItems = sliced;
        setFilters(data.productFilters);
        setSelectedFilters([]);
        setSelectedSorter(
          data.productSearchResult.sorters.find(x => x.selected)
        );
        setSorters(data.productSearchResult.sorters);
      } else {
      }
      dispatchLoading('remove');
    });
  }, [pageId]);

  const loadMoreItems = () => {
    if (preFetchedData) {
      setIsLoadingMore(true);
      let sliced = preFetchedData.products?.items?.slice(
        cache.products?.visibleItems?.length,
        cache.products?.visibleItems?.length + itemsPerLoad
      );
      let itemsToShow = cache.products?.visibleItems?.concat(sliced);
      cache.products.availableItems = nrOfVisibleItems + itemsPerLoad;
      cache.products.visibleItems = itemsToShow;
      setIsLoadingMore(false);
      nrOfVisibleItems && setNrOfVisibleItems(nrOfVisibleItems + itemsPerLoad);
    }
  };

  const onFilterChange = (filter: string[], checked: boolean) => {
    let filters: string[] = [];
    if (checked) {
      filters = filter.concat(selectedFilters);
      setSelectedFilters(filters);
    } else {
      filters = selectedFilters.slice(0, selectedFilters.length);
      filters.splice(filters.indexOf(filter[0]), 1);
      setSelectedFilters(filters);
    }
    updateFilters(filters);
  };

  const updateFilters = (filters: string[]) => {
    let filtersString: string = `${filters.map(x => `${x}`).join('')}`;
    dispatchLoading('add');
    GetCategoryItems(
      pageId,
      channelId,
      languageRoute,
      requestToken,
      organizationId,
      undefined,
      filtersString
    ).then(data => {
      if (data) {
        setPreFetchedData((prev: any) => ({
          ...prev,
          products: data.productSearchResult,
        }));
        cache.products.nrOfItems = data.productSearchResult?.numberOfItems;
        let sliced = data.productSearchResult?.items.slice(0, itemsPerLoad);
        if (data.productSearchResult?.items) {
          cache.products.visibleItems = sliced;
        }
        if (data.productFilters) {
          cache.products.filters = data.productFilters;
        }
        cache.products.visibleItems = sliced;
        data.productFilters && setFilters(data.productFilters);
        data.productSearchResult &&
          setSorters(data.productSearchResult?.sorters);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(sorter => sorter.selected)
        );
        setNrOfVisibleItems(itemsPerLoad);
      }
      dispatchLoading('remove');
    });
  };

  useOutsideClick(sorterRef, () => {
    sortModalOpen && setSortModalOpen(false);
  });

  useOutsideClick(categoryRef, () => {
    categoryModalOpen && setCategoryModalOpen(false);
  });

  useOutsideClick(filterRef, () => {
    filterModalOpen && setFilterModalOpen(false);
  });

  const onSorterChange = (sortBy: string, sortDirection: number) => {
    setSortModalOpen(false);
    if (preFetchedData != undefined) {
      let filters: string = '';
      if (selectedFilters && !!selectedFilters.length) {
        filters = `${selectedFilters.map(x => `${x}`).join('')}`;
      }
      dispatchLoading('add');
      GetCategoryItems(
        pageId,
        channelId,
        languageRoute,
        requestToken,
        organizationId,
        undefined,
        filters,
        sortBy,
        sortDirection
      ).then(data => {
        setPreFetchedData((prev: any) => ({
          ...prev,
          products: data.productSearchResult,
        }));
        let sliced = data.productSearchResult?.items.slice(0, itemsPerLoad);
        cache.products.visibleItems = sliced;
        cache.products.sorters = data.productSearchResult?.sorters;
        setSorters(data.productSearchResult?.sorters);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(sorter => sorter.selected)
        );
        setNrOfVisibleItems(itemsPerLoad);
        dispatchLoading('remove');
      });
    }
  };

  const onFilterClick = (val: string, checked: boolean, filterType: string) => {
    let filters: string[] = [];
    let filterQuery = `&${filterType}=${val}`;
    filters.push(filterQuery);
    onFilterChange(filters, checked);
  };

  const onCategoryButtonClick = () => {
    if (!categoryModalOpen) {
      topOptionsRef?.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
    setCategoryModalOpen(!categoryModalOpen);
    setShowMoreCategories(false);
  };

  const onFilterButtonClick = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  const onSortButtonClick = () => {
    setSortModalOpen(!sortModalOpen);
  };
  console.log(preFetchedData);
  return (
    <Main>
      {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />}
      <TopSection>
        <Heading>{pageHeading}</Heading>
        {pageIntroText && <PageIntro>{pageIntroText}</PageIntro>}
      </TopSection>
      {!isDesktop && (
        <>
          <OptionsArea>
            <TopOptionsWrapper>
              {preFetchedData && (
                <OptionsButtonWrapper
                  data-fullwidth={filters && !!!filters.length}
                  data-isactive={categoryModalOpen}
                >
                  <OptionsButton
                    data-isopen={categoryModalOpen}
                    onClick={() => onCategoryButtonClick()}
                  >
                    <span>{sectorLabel}</span>
                    <ArrowDownWrapper data-isopen={categoryModalOpen}>
                      <StyledArrowDown />
                    </ArrowDownWrapper>
                  </OptionsButton>
                </OptionsButtonWrapper>
              )}
              {filters && !!filters.length && (
                <OptionsButtonWrapper
                  ref={filterModalOpen ? filterRef : undefined}
                  data-isactive={filterModalOpen}
                >
                  <OptionsButton
                    data-isopen={filterModalOpen}
                    onClick={() => onFilterButtonClick()}
                  >
                    <span>{filterLabel}</span>
                    <ArrowDownWrapper data-isopen={filterModalOpen}>
                      <StyledArrowDown />
                    </ArrowDownWrapper>
                  </OptionsButton>
                </OptionsButtonWrapper>
              )}
            </TopOptionsWrapper>
            {categoryModalOpen && (
              <OptionsModalContainer>
                <OptionsModal ref={categoryRef}>
                  <ListContainer data-isopen={showMoreCategories}>
                    {commerceLinks &&
                      !!commerceLinks.length &&
                      commerceLinks.map((link: IHeaderLink) => (
                        <CategoryLink
                          key={link.id}
                          data-isactive={link.id === pageId}
                          href={link.href}
                          onClick={() => setCategoryModalOpen(false)}
                        >
                          {link.text}
                        </CategoryLink>
                      ))}
                  </ListContainer>
                  {commerceLinks &&
                    commerceLinks.length > 4 &&
                    !showMoreCategories && (
                      <ShowMoreButton
                        ref={categoryRef}
                        onClick={() => setShowMoreCategories(true)}
                      >
                        <div>
                          <StyledPlusIcon />
                        </div>
                        {showMoreLabel}
                      </ShowMoreButton>
                    )}
                </OptionsModal>
              </OptionsModalContainer>
            )}
            {filterModalOpen && (
              <OptionsModalContainer ref={filterRef}>
                <OptionsModal>
                  <ListContainer data-isopen={true}>
                    {filters &&
                      filters.map(filter => (
                        <div key={filter.name}>
                          <FilterType>{filter.name}</FilterType>
                          {filter.links.map(filterLink => (
                            <FilterItem
                              key={filterLink.attributes.value}
                              filter={filterLink}
                              filterType={filter.attributes.value}
                              onFilterChange={onFilterClick}
                              selected={selectedFilters.includes(
                                `&${filter.attributes.value}=${filterLink.name}`
                              )}
                            />
                          ))}
                        </div>
                      ))}
                  </ListContainer>
                </OptionsModal>
              </OptionsModalContainer>
            )}
            {selectedSorter && (
              <OptionsButtonWrapper
                ref={sorterRef}
                data-fullwidth={true}
                data-isactive={sortModalOpen}
              >
                <OptionsButton
                  data-isopen={sortModalOpen}
                  onClick={() => onSortButtonClick()}
                >
                  <span>{selectedSorter?.text}</span>{' '}
                  <ArrowDownWrapper data-isopen={sortModalOpen}>
                    <StyledArrowDown />
                  </ArrowDownWrapper>
                </OptionsButton>
              </OptionsButtonWrapper>
            )}
            {sortModalOpen && (
              <OptionsModalContainer>
                <OptionsModal>
                  {sorters !== undefined &&
                    sorters.map(
                      (sorter: SearchSorter) =>
                        !sorter.selected && (
                          <SorterListItem
                            key={sorter.value}
                            onClick={e =>
                              onSorterChange(
                                sorter.sortBy,
                                sorter.sortDirection
                              )
                            }
                          >
                            {sorter.text}
                          </SorterListItem>
                        )
                    )}
                </OptionsModal>
              </OptionsModalContainer>
            )}
          </OptionsArea>
        </>
      )}
      <ProductContainer>
        {isDesktop && (
          <FilterContainerDesktop
            pageId={pageId}
            commerceLinks={commerceLinks}
            filters={filters}
            onFilterClick={onFilterClick}
            selectedFilters={selectedFilters}
          />
        )}
        <StyledCardGrid>
          {isDesktop && selectedSorter && (
            <SorterWrapper ref={sorterRef}>
              <Sorter onClick={() => setSorterIsOpen(!sorterIsOpen)}>
                <SelectedSorter>
                  <SelectedSorterWrapper>
                    <span>
                      {sortOnLabel} <b>{selectedSorter?.text}</b>
                    </span>
                    <ArrowDownWrapper data-isopen={sorterIsOpen}>
                      <ArrowDownThick />
                    </ArrowDownWrapper>
                  </SelectedSorterWrapper>
                  {sorterIsOpen && (
                    <SorterList>
                      {sorters !== undefined &&
                        sorters.map(
                          (sorter: SearchSorter) =>
                            !sorter.selected && (
                              <SorterListItem
                                key={sorter.value}
                                onClick={e =>
                                  onSorterChange(
                                    sorter.sortBy,
                                    sorter.sortDirection
                                  )
                                }
                              >
                                {sorter.text}
                              </SorterListItem>
                            )
                        )}
                    </SorterList>
                  )}
                </SelectedSorter>
              </Sorter>
            </SorterWrapper>
          )}
          {(preFetchedData &&
            preFetchedData.products &&
            preFetchedData.products.nrOfItems > 0) ||
            (!!selectedFilters.length && !preFetchedData?.products && (
              <NoResultText>{noFilterResultLabel}</NoResultText>
            ))}
          {cache.products?.visibleItems?.map((product: ProductCardModel) => (
            <ProductCard key={product.code} item={product} />
          ))}
          {cache.products?.visibleItems &&
            !!cache.products?.visibleItems?.length && (
              <LoadMoreContainer
                numberOfItems={cache.products?.visibleItems?.length}
                onChange={() => loadMoreItems()}
                availableItems={cache.products.nrOfItems}
                disabled={disableButton}
                isLoadingMore={isLoadingMore}
              />
            )}
        </StyledCardGrid>
      </ProductContainer>
    </Main>
  );
}

const Main = styled.main({
  backgroundColor: theme.primaryBackground,
});

const TopSection = styled.div({
  margin: { y: theme.spacing(8), x: 'auto' },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: '15px',
  },
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { y: theme.theta, x: 'auto' },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { top: theme.spacing(24), bottom: theme.spacing(13) },
    padding: { x: theme.medium, y: theme.none },
    maxWidth: theme.maxWidthContentPage,
  }),
});

const NoResultText = styled.div({
  gridColumnEnd: 'span 12',
  display: 'flex',
  justifyContent: 'center',
  color: theme.black,
  ...media(mediaQuery.mediaMinLarge, {
    marginTop: theme.spacing(16),
  }),
});

const StyledCardGrid = styled(CardGrid, { maxWidth: theme.screenMaxWidth });

const OptionsArea = styled.div({
  marginTop: theme.spacing(5),
  margin: { x: '15px' },
});

const ArrowDownWrapper = styled.div({
  marginLeft: theme.spacing(2),
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    transform: 'rotate(180deg)',
  }),
});

const OptionsModalContainer = styled.div({ position: 'relative', zIndex: 10 });

const OptionsModal = styled.div({
  color: theme.black,
  position: 'absolute',
  backgroundColor: theme.white,
  top: theme.spacing(1),
  padding: { xy: theme.spacing(1) },
  borderRadius: '6px',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
  width: '100%',
  listStyle: 'none',
});

const SelectedSorterWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Sorter = styled.div({
  backgroundColor: theme.white,
  borderRadius: '10px',
});

const ListContainer = styled.div({
  maxHeight: '244px',
  overflow: 'hidden',
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    maxHeight: '400px',
    overflowY: 'scroll',
    ...pseudo('::-webkit-scrollbar', {
      height: '12px',
      width: '8px',
      borderRadius: '100px',
      backgroundColor: theme.white,
      border: {
        y: {
          style: 'solid',
          width: theme.tiny,
          color: theme.veryLightGray,
        },
      },
    }),
    ...pseudo('::-webkit-scrollbar-thumb', {
      width: '60px',
      borderRadius: '100px',
      backgroundColor: theme.primaryBlue,
    }),
  }),
});

const SelectedSorter = styled.div({
  position: 'relative',
  padding: { xy: theme.spacing(4) },
  cursor: 'pointer',
});

const SorterList = styled.ul({
  position: 'absolute',
  listStyle: 'none',
  overflow: 'hidden',
  backgroundColor: theme.white,
  paddingTop: theme.spacing(3),
  zIndex: 10,
  width: '100%',
  left: 0,
  right: 0,
  borderRadius: '10px',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
});

const SorterListItem = styled.li({
  fontSize: '14px',
  padding: { xy: theme.spacing(4) },
  ':hover': {
    backgroundColor: theme.lightBlue,
  },
});

const ShowMoreButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: theme.black,
  font: { size: '14px', weight: theme.fontWeight.bold },
  margin: { x: '-5px', top: theme.spacing(3) },
  padding: { xy: theme.spacing(5), bottom: theme.spacing(4) },
  border: {
    top: {
      width: '1px',
      style: 'solid',
      color: theme.veryLightGray,
    },
  },
  textTransform: 'uppercase',
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  width: '16px',
  height: '16px',
  marginRight: theme.spacing(2),
});

const CategoryLink = styled(KexLink, {
  display: 'flex',
  fontSize: '14px',
  color: theme.black,
  lineHeight: '16.8px',
  padding: { xy: theme.spacing(4) },
  justifyContent: 'space-between',
  ...pseudo([':nth-child(n)[data-isactive="true"]'], {
    borderRadius: '10px',
    backgroundColor: theme.lightBlue,
  }),
});

const TopOptionsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: theme.spacing(4),
});

const SorterWrapper = styled.div({
  color: theme.black,
  fontSize: '14px',
  gridColumnEnd: 'span 12',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '-40px',
  alignItems: 'center',
});

const StyledArrowDown = styled(ArrowDown, {
  fill: theme.black,
  height: '6px',
  width: '14px',
});

const OptionsButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: theme.black,
  justifyContent: 'space-between',
  backgroundColor: theme.white,
  flexBasis: '50%',
  maxWidth: '50%',
  lineHeight: '16.8px',
  border: {
    radius: '8px',
    xy: {
      width: '1px',
      style: 'solid',
      color: theme.veryLightGray,
    },
  },
  ...pseudo([':nth-child(n)[data-fullwidth="true"]'], {
    width: '100%',
    maxWidth: '100%',
    flexBasis: '100%',
    marginTop: theme.spacing(5),
  }),
  ...pseudo([':nth-child(n)[data-isactive="true"]'], {
    border: { xy: { color: theme.iconLightBlue } },
  }),
});

const OptionsButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: theme.black,
  justifyContent: 'space-between',
  padding: { x: theme.spacing(6), y: theme.spacing(4) },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    fontWeight: theme.fontWeight.bold,
  }),
});

const PageIntro = styled.span({
  color: theme.black,
  lineHeight: '28.8px',
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.epsilon,
    lineHeight: '32px',
  }),
});

const FilterType = styled.div({
  padding: {
    x: theme.spacing(4),
    top: theme.spacing(4),
    bottom: theme.spacing(2),
  },
  font: { size: '16px', weight: theme.fontWeight.bold },
});

const Heading = styled.h1({
  color: theme.black,
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: '32px',
    lineHeight: '40px',
  }),
});

const ProductContainer = styled.div({
  margin: { x: '15px', y: theme.great },
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: theme.screenMaxWidth,
    margin: { x: 'auto', top: theme.spacing(12) },
    padding: { x: theme.spacing(10) },
    display: 'flex',
  }),
});

export default CategoryPage;
