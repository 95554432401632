import React from 'react';
import { theme, styled } from '../../Theme';
import { media } from '@glitz/core';
import { StyledProps } from '@glitz/react';

type PropTypes = StyledProps & {
  children: React.ReactNode;
};

function CardGrid({ children, compose, ...rest }: PropTypes) {
  return (
    <Grid css={compose()} {...rest}>
      {children}
    </Grid>
  );
}

const Grid = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
  gridRowGap: theme.spacing(9),
  gridColumnGap: theme.spacing(4),
  // gridGap: theme.tiny,
  margin: { x: 'auto', bottom: 'auto' },
  ...media(theme.mediaQuery.mediaMinSmall, {
    // gridTemplateColumns: '1fr 1fr',
    // gridGap: theme.tiny,
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: 'repeat(6,minmax(0,1fr))',
    // gridGap: theme.large,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(12,minmax(0,1fr))',
    gridRowGap: theme.spacing(12),
    gridColumnGap: theme.spacing(6),
    // gridGap: theme.large,
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    // gridTemplateColumns: '1fr 1fr 1fr 1fr',
    // gridGap: theme.great,
  }),
});

export default CardGrid;
