import React from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import { mediaQuery } from '../../Theme/mediaQueries';
import { LoadingCircle } from '../Icons';
import CtaButton from '../CtaButton/CtaButton';

type PropType = {
  numberOfItems: number;
  onChange: () => void;
  availableItems: number;
  disabled?: boolean;
  isLoadingMore?: boolean;
  isPlanograms?: boolean;
};

const LoadMoreContainer = ({
  numberOfItems,
  onChange,
  availableItems,
  disabled,
  isLoadingMore,
  isPlanograms,
}: PropType) => {
  const {
    translations: {
      'common/showMore': showMoreLabel,
      'common/showMoreText': showMoreTextLabel,
      'common/products': productsLabel,
      'common/planogram': planogramLabel,
    },
  } = useAppSettingsData();

  return (
    <LoadMoreContentContainer>
      <ProgressBarWrapper>
        <ProgressBarLabel htmlFor="progress-bar">
          {showMoreTextLabel &&
            showMoreTextLabel.replace(
              '{0}',
              numberOfItems >= availableItems
                ? availableItems.toString()
                : numberOfItems.toString()
            )}{' '}
          {availableItems}{' '}
          {!isPlanograms
            ? productsLabel.toLowerCase()
            : planogramLabel.toLowerCase()}
        </ProgressBarLabel>
        <ProgressBar
          id="progress-bar"
          value={numberOfItems}
          max={availableItems}
        />
      </ProgressBarWrapper>
      <LoadMoreButton disabled={disabled} onClick={() => onChange()}>
        <ButtonText data-isloading={isLoadingMore}>{showMoreLabel}</ButtonText>
        {isLoadingMore && <StyledLoadingCircle isLoading={isLoadingMore} />}
      </LoadMoreButton>
    </LoadMoreContentContainer>
  );
};

export default LoadMoreContainer;

const LoadMoreContentContainer = styled.div({
  gridColumnEnd: 'span 4',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: { x: 'auto', top: 0, bottom: theme.spacing(6) },
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridColumnEnd: 'span 6',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnEnd: 'span 12',
    margin: { top: '22px', bottom: theme.spacing(16) },
  }),
});

const ProgressBarWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const ProgressBarLabel = styled.label({
  fontSize: '16px',
  color: theme.black,
  textAlign: 'center',
  marginBottom: theme.spacing(3),
});

const ProgressBar = styled.progress({
  maxWidth: '345px',
  width: '100%',
  height: '8px',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
  ...pseudo(['::-moz-progress-bar'], {
    backgroundColor: 'white',
    borderRadius: '8px',
  }),
  ...pseudo(['::-webkit-progress-value'], {
    backgroundColor: theme.iconLightBlue,
    borderRadius: '8px',
  }),
  ...pseudo(['::-webkit-progress-bar'], {
    backgroundColor: 'white',
    borderRadius: '8px',
  }),
});

const LoadMoreButton = styled(CtaButton, {
  maxWidth: '365px',
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  fontSize: theme.tau,
  padding: { xy: theme.spacing(3) },
  minHeight: '40px',
  margin: { x: theme.spacing(4), top: theme.spacing(4) },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { xy: theme.spacing(4) },
    width: '365px',
  }),
});

const ButtonText = styled.span({
  color: theme.white,
  fontSize: '15px',
  transform: 'uppercase',
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});
