import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ArrowDownThick({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15122 1.96736C1.06744 1.87992 1.06968 1.74134 1.15623 1.65665L1.66677 1.15705C1.75498 1.07073 1.8968 1.07343 1.98167 1.16303L6.29114 5.71321L10.7864 1.15666C10.8718 1.07005 11.0112 1.06883 11.0982 1.15391L11.6092 1.65402C11.6968 1.73975 11.6979 1.88043 11.6116 1.96747L6.61031 7.01042C6.43537 7.18682 6.14944 7.18435 5.97757 7.00496L1.15122 1.96736Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
      />
    </styled.Svg>
  );
}

export default styled(ArrowDownThick);
