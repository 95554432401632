import React, { useState } from 'react';

import { theme, styled } from '../../Theme';
import { pseudo } from '@glitz/core';

import CheckIcon from '../../Shared/Icons/CheckIcon';

type PropType = {
  name: string;
  value: boolean;
  label?: string;
  checked?: boolean;
  onChange?: (value: string, checked: boolean, filterType: string) => void;
  filterType?: string;
};

function Checkbox({
  name,
  value,
  label,
  checked = false,
  onChange,
  filterType,
}: PropType) {
  const [isChecked, setIsChecked] = useState(checked);

  const onInputChange = (checked: boolean, val: string) => {
    setIsChecked(checked);
    onChange && filterType && onChange(val, checked, filterType);
  };

  return (
    <Root>
      <LabelContainer>
        <Label htmlFor={name + value}>
          <LabelInner>
            <Input
              data-ischecked={isChecked}
              checked={isChecked}
              id={name + value}
              name={name}
              value={encodeURIComponent(value)}
              type="checkbox"
              onChange={e => onInputChange(e.target.checked, name)}
            />
            <Square data-ischecked={isChecked} id="square">
              <StyledCheckIcon />
            </Square>

            <FilterText>{label}</FilterText>
          </LabelInner>
        </Label>
      </LabelContainer>
    </Root>
  );
}

const Root = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
});

const LabelContainer = styled.div({
  display: 'flex',
  whiteSpace: 'pre-wrap',
  width: '100%',
});

const LabelInner = styled.div({ display: 'inline-flex', alignItems: 'center' });

const Input = styled.input({
  opacity: 0,
  position: 'absolute',
});

const StyledCheckIcon = styled(CheckIcon, {
  position: 'absolute',
  height: '18px',
  width: '14px',
  zIndex: 10,
  left: '1px',
});

const FilterText = styled.span({});

const Square = styled.div({
  position: 'relative',
  marginRight: '16px',
  left: 0,
  width: '18px',
  height: '18px',
  borderRadius: '5px',
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.primaryBlue,
    },
  },
  pointerEvents: 'none',
  transition: {
    property: 'opacity',
    duration: '0.3s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-ischecked="true"]'], {
    ...pseudo(':after', {
      transform: 'scale(1)',
    }),
  }),
  ...pseudo(':after', {
    width: '16px',
    height: '100%',
    borderRadius: '3px',
    content: '" "',
    display: 'block',
    position: 'relative',
    backgroundColor: theme.primaryBlue,
    transform: 'scale(0)',
    transition: {
      property: 'transform',
      duration: '0.2s',
      timingFunction: 'linear',
    },
  }),
});

const Label = styled.label({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: '14px',
  cursor: 'pointer',
});

export default Checkbox;
