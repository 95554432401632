import React, { useState, useEffect } from 'react';
import { styled, theme } from '../Theme';
import Image from '../Shared/Image/Image';
import { LoadingCircle } from '../Shared/Icons';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { UpdateCart } from '../Cart/Cart';
import ProductCardModel from './Models/ProductCardModel.interface';
import CtaButton from '../Shared/CtaButton/CtaButton';
import { pseudo, media } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import CheckIcon from '../Shared/Icons/CheckIcon';
import KexLink from '../Shared/KexLink/KexLink';

type ProductCardType = {
  item: ProductCardModel;
  inEditMode?: Boolean;
  isPlanogram?: boolean;
};

function ProductCard({
  item: {
    image,
    name,
    code,
    url,
    price,
    quantity,
    inStock,
    mainCategory,
    unitPrice,
  },
  isPlanogram = false,
}: ProductCardType) {
  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'productPage/inStock': inStockLabel,
      'productPage/notInStock': notInStockLabel,
      'common/quantity': quantityLabel,
      'common/perPackage': perPackageLabel,
      'common/unitPrice': unitPriceLabel,
      'common/piece': pieceLabel,
      'common/moreInformation': moreInformationLabel,
      'productPage/addedToCart': addedToCartLabel,
    },
    placeholderImages: {
      productPlaceholder: productPlaceholderImg,
      planogramPlaceholder: planogramPlaceholderImg,
    },
    languageRoute,
  } = useAppSettingsData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputQuantity, setInputQuantity] = useState<string>('1');
  const [itemAdded, setItemAdded] = useState(false);

  const onInputChange = (value: string) => {
    if (Number(value) < 0) {
      setInputQuantity('1');
      return;
    }
    setInputQuantity(value);
  };

  const onInputBlur = (numb: string) => {
    Number(numb) < 1 && setInputQuantity('1');
  };

  const addToCart = () => {
    const updated = UpdateCart(
      code,
      Number(inputQuantity),
      languageRoute,
      '',
      setIsLoading
    );
    if (updated) {
      setItemAdded(true);
      setTimeout(() => {
        setItemAdded(false);
      }, 2000);
    }
  };
  return (
    <>
      <Root>
        <Card>
          <ImageArea href={url}>
            <ProductImage
              src={
                image
                  ? image.src
                  : !isPlanogram
                  ? productPlaceholderImg?.src
                  : planogramPlaceholderImg?.src
              }
              lazy={false}
              alt={image ? image.alt : ''}
            />
            <StockStatus>
              {inStock ? (
                <>
                  <StockStatusCircle data-instock={inStock} />
                  <StockStatusText>{inStockLabel}</StockStatusText>
                </>
              ) : (
                <>
                  <StockStatusCircle />
                  <StockStatusText>{notInStockLabel}</StockStatusText>
                </>
              )}
            </StockStatus>
          </ImageArea>
          <ProductCardInfo>
            <ProductSection>{mainCategory}</ProductSection>
            <ProductName>{name}</ProductName>
            <PriceContainer>
              <MainPriceContainer>
                <Price>{price}</Price>
                <PerPackageText>/{perPackageLabel}</PerPackageText>
              </MainPriceContainer>
              <div>
                <QuantityPriceWrapper>
                  <QuantityText>
                    {quantityLabel}/{perPackageLabel}:&nbsp;
                  </QuantityText>
                  <QuantityText>
                    {quantity}
                    {pieceLabel}
                  </QuantityText>
                </QuantityPriceWrapper>
                <QuantityPriceWrapper>
                  <QuantityText>{unitPriceLabel}:&nbsp;</QuantityText>
                  <QuantityText>{unitPrice}:-</QuantityText>
                </QuantityPriceWrapper>
              </div>
            </PriceContainer>
            {inStock && (
              <AddToCart>
                {!isPlanogram ? (
                  <>
                    <QuantitySelector>
                      <MinusButton
                        disabled={inputQuantity === '1'}
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) - 1).toString()
                          )
                        }
                      >
                        <StyledMinusIcon />
                      </MinusButton>
                      <QuantityInput
                        type={'number'}
                        min={'1'}
                        max={'99'}
                        value={inputQuantity}
                        onChange={e => onInputChange(e.target.value)}
                        onBlur={e => onInputBlur(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) + 1).toString()
                          )
                        }
                      >
                        <StyledPlusIcon />
                      </button>
                    </QuantitySelector>
                    <AddToCartButton onClick={() => addToCart()}>
                      <ButtonText data-isloading={isLoading || itemAdded}>
                        {addToCartLabel}
                      </ButtonText>
                      {isLoading && (
                        <StyledLoadingCircle isLoading={isLoading} />
                      )}
                      {itemAdded && !isLoading && (
                        <ItemAddedText>
                          {addedToCartLabel}
                          <StyledCheckIcon />
                        </ItemAddedText>
                      )}
                    </AddToCartButton>
                  </>
                ) : (
                  <MoreInfoButton href={url} asLink={true}>
                    {moreInformationLabel}
                  </MoreInfoButton>
                )}
              </AddToCart>
            )}
          </ProductCardInfo>
        </Card>
      </Root>
    </>
  );
}
const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.white,
  overflow: 'hidden',
  borderRadius: '10px',
  minWidth: theme.none,
  width: '100%',
  transition: {
    property: 'box-shadow',
  },
  ...media(mediaQuery.mediaMinLarge, {
    ':hover': {
      boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.2)',
    },
  }),
});

const ImageArea = styled(KexLink, {
  position: 'relative',
  backgroundColor: theme.veryLightGray,
});

const ProductImage = styled(Image, {
  height: '135px',
  width: '96px',
  margin: { x: 'auto', y: theme.spacing(3) },
  objectFit: 'contain',
});

const StyledCheckIcon = styled(CheckIcon, {
  height: '14px',
  width: '14px',
  marginLeft: '8px',
});

const StockStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  padding: { y: '7px', x: '10px' },
  backgroundColor: theme.white,
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  margin: { bottom: theme.spacing(3) },
});

const StockStatusCircle = styled.div({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: theme.notInStockRed,
  marginRight: theme.spacing(2),
  ...pseudo([':nth-child(n)[data-instock="true"]'], {
    backgroundColor: theme.inStockGreen,
  }),
});

const StockStatusText = styled.span({
  color: theme.black,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const ItemAddedText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ProductCardInfo = styled.div({
  padding: {
    xy: '10px',
  },
  backgroundColor: theme.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ProductSection = styled.p({
  fontSize: theme.large,
  color: theme.middleGray,
  lineHeight: '14.4px',
});

const ProductName = styled.p({
  font: { size: '20px', weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '21.6px',
  margin: {
    top: theme.small,
    bottom: theme.large,
  },
});

const PriceContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: {
    top: 'auto',
    bottom: theme.small,
  },
});

const MainPriceContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const QuantityPriceWrapper = styled.div({
  fontSize: '15px',
  display: 'flex',
  justifyContent: 'end',
  ...pseudo(':not(:last-child)', { marginBottom: theme.medium }),
});

const Price = styled.span({
  font: {
    size: theme.moreLarge,
    weight: theme.fontWeight.boldest,
  },
  marginTop: theme.spacing(2),
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  color: theme.black,
});

const PerPackageText = styled.p({
  marginTop: theme.spacing(4),
  fontSize: '12px',
  marginLeft: '3px',
});

const QuantityText = styled.span({
  color: theme.middleGray,
  fontSize: theme.large,
});

const AddToCartButton = styled(CtaButton, {
  position: 'relative',
  alignItems: 'center',
  width: '100%',
  fontSize: theme.tau,
  padding: { xy: '10px' },
  marginLeft: theme.spacing(2),
  minHeight: '40px',
  ...media(mediaQuery.mediaMinLarge, {
    padding: { xy: theme.spacing(3) },
  }),
});

const MoreInfoButton = styled(CtaButton, {
  position: 'relative',
  alignItems: 'center',
  width: '100%',
  fontSize: theme.tau,
  padding: { xy: '10px' },
  minHeight: '40px',
  ...media(mediaQuery.mediaMinLarge, {
    padding: { xy: theme.spacing(3) },
  }),
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const AddToCart = styled.div({
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'bottom',
  backgroundColor: theme.primaryBackground,
  padding: { xy: theme.spacing(3) },
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const QuantitySelector = styled.div({
  backgroundColor: theme.white,
  borderRadius: '100px',
  display: 'flex',
  height: '40px',
  alignItems: 'center',
  padding: { x: theme.spacing(3) },
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const QuantityInput = styled.input({
  width: theme.spacing(7),
  backgroundColor: theme.white,
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const Root = styled.div({
  gridColumnEnd: 'span 4',
  display: 'flex',
  width: '100%',
  minWidth: theme.none,
  borderRadius: '10px',
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnEnd: 'span 2',
    gridGap: theme.tiny,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnEnd: 'span 6',
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridColumnEnd: 'span 4',
  }),
  ...media(theme.mediaQuery.mediaMinScreen, {
    gridColumnEnd: 'span 3',
  }),
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export default ProductCard;
