import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { theme, styled } from '../../Theme';
import KexContentLinkModel from '../../Search/Models/KexContentLinkModel.interface';
import { media } from '@glitz/core';
import { mediaQuery } from '../../Theme/mediaQueries';

type PropType = {
  filter: KexContentLinkModel;
  filterType: string;
  onFilterChange?: any;
  selected?: boolean;
};

function FilterItem({
  filter,
  filterType,
  onFilterChange,
  selected,
}: PropType) {
  return (
    <Wrapper>
      <Checkbox
        name={filter.name}
        value={true}
        label={filter.name}
        onChange={onFilterChange}
        filterType={filterType}
        checked={selected}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div({
  ...media(mediaQuery.mediaMaxLarge, {
    paddingLeft: theme.spacing(4),
  }),
  padding: { y: theme.spacing(4) },
});

export default FilterItem;
